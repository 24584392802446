import api from '@/request/api'
import store from '@/store/index'
import { Toast } from 'vant'

const device = {
  getLocalIP() {
    return new Promise((resolve, reject) => {
      // store.commit('updateDeviceIp', '192.168.1.41');
      // store.commit('updateDeviceMac', '14:98:77:44:69:28');
      // resolve();
      api.getLocalIp().then(res => {
        let ips = res.ips;
        if (Array.isArray(ips) && ips.length > 0) {
          store.commit('updateDeviceIp', ips[0].address);
          store.commit('updateDeviceMac', ips[0].mac);
          resolve();
        } else {
          store.commit('updateDeviceIp', '');
          store.commit('updateDeviceMac', '');
          resolve();
        }
      }).catch(() => {
        store.commit('updateDeviceIp', '');
        store.commit('updateDeviceMac', '');
        resolve();
      })
    })
  },
  // 打开钱箱
  openCasher() {
    return new Promise((resolve, reject) => {
      api.openCasher().then(res => {
        // console.log('打开钱箱成功', res);
        resolve(1);
      }).catch((err) => {
        // this.openCasher();
        Toast('打开钱箱失败');
        resolve(2);
        // console.log('打开钱箱失败', err);
      })
    })
  }
}

export default device