import request from '@/request/http';

const pb = {
    // 生成订单
    payOrder: data => request({url: 'Order/payOrder', data, method: 'post' }),
    // 取单-选中指定挂单数据
    getHang: data => request({url: 'Order/getHang', data}),
    // 体验账号登录的提交（联系我们）
    subForm: data => request({url: 'subForm', data, method: 'POST'}),
    // 体验账号获取验证码
    getCaptcha: data => request({url: 'getCode', data, method: 'GET'}),
    // 文件上传
    upload: data => request({url: 'CImage/upload', data, method: 'POST'}),
    // 商品分类
    goodsType: data => request({url: 'Category/getShopCategoryList', data, method: 'GET'}),
    // 商品单位
    goodsUnit: data => request({url: 'Goods/unit', data, method: 'GET'}),
    // 添加商品
    addShopGoods: data => request({url: '/Good/addShopGoods', data, method: 'POST'}),
    // 获取商品
    getShopGoods: data => request({url: '/Good/getShopGoods', data, method: 'GET'}),
    // 编辑商品
    editShopGoods: data => request({url: '/Good/editShopGoods', data, method: 'POST'}),
    // 删除商品
    delShopGoods: data => request({url: '/Good/delShopGoods', data, method: 'POST'}),
    // 编辑分类
    editShopCategory: data => request({url: '/Category/editShopCategory', data, method: 'POST'}),
    // 新增分类
    addShopCategory: data => request({url: '/Category/addShopCategory', data, method: 'POST'}),
    // 删除分类
    delShopCategory: data => request({url: '/Category/delShopCategory', data, method: 'POST'}),
    // 下单--人工手动支付
    handPay: data => request({url: '/Order/handPay', data, method: 'POST'}),
    // 重新查询订单
    againQueryOrder: data => request({url: '/Order/againQueryOrder', data, method: 'POST'}),
    // 查询订单支付状态(退款操作)
    queryOrderPay: data => request({url: '/Order/queryOrderPay', data, method: 'GET'}),
    // 多退少补生成订单
    getHxOrderAmount: data => request({ url: '/Hj/getHxOrderAmount', data, method: 'POST' }),
    // 多退少补支付（退款）
    onlineOrderPayOrReturn: data => request({ url: '/Hj/onlineOrderPayOrReturn', data, method: 'POST' }),
}

export default pb;
