
// 商米电子称服务
class ScaleService {
    constructor() {}
    // 链接电子秤服务
    static connectScaleService() {
        return KissSunmiPrint.connectScaleService();
    }

    // 连续获取电子秤净重、状态、数值返回
    static getScaleDataKeepAlive() {
        KissSunmiPrint.getScaleDataKeepAlive();
    }

    // 净重清零
    static myZeros() {
        return KissSunmiPrint.myZeros();
    }

    // 关闭电子秤服务
    static onDestroy() {
        return KissSunmiPrint.onDestroy();
    }
}

export default ScaleService;
