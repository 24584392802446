import { getLodop } from "./LodopFuncs";
import lodopHelper from './lodopHtmlTemp'
import store from '../store'

export function printLodopTemp(data) {
  const printWidth = parseInt(localStorage.qiantaiPrintSize) || 58;
  // console.log('printLodopTemp', JSON.stringify(data), printWidth);
  return new Promise(async (resolve, reject) => {
    const html = await lodopHelper.print(data);
    document.getElementById("printArea").innerHTML = html;
    let LODOP = getLodop();
    LODOP.PRINT_INIT(new Date().getTime()); // 初始化
    LODOP.ADD_PRINT_HTM(0,0,document.getElementById("printArea").offsetWidth,document.getElementById("printArea").offsetHeight,document.getElementById("printArea").innerHTML);
    LODOP.SET_PRINT_PAGESIZE(3, printWidth == 58 ? 580 : 700, 45, ""); // 设置纸张大小,方向
    // LODOP.PREVIEW();
    LODOP.PRINT();
    resolve();
  })
}

// 更新电子秤信息
export function getThisWeightDetail() {
  window.addEventListener('message', function (data) {
    // console.log('getThisWeightDetail message------------', data);
    if (data.data.weight) {
      store.commit('updateWeightDetail', data.data.weight);
    }
  }, false);
}

// 获取所有设备
export function getAllPrinters() {
  //获取打印机设备数，用于进行遍历
  let arr = []
  let LODOP = getLodop();
  var count = LODOP.GET_PRINTER_COUNT(); //获取打印机个数
  if(count && count > 0 ){
    for (var i = 0; i < count; i++) {
      //根据设备序号获取设备名
      // var msg = LODOP.GET_PRINTER_NAME(i);
      arr.push(LODOP.GET_PRINTER_NAME(i));
      console.log(LODOP.GET_PRINTER_NAME(i), 'PaperWidth', LODOP.GET_PRINTER_NAME(i + ':PaperWidth'));
      console.log(LODOP.GET_PRINTER_NAME(i), 'MaxExtentWidth', LODOP.GET_PRINTER_NAME(i + ':MaxExtentWidth'));
    }
    return arr
  }else{
    // console.log('none printer exists')
    return arr;
  }
}

// 标签制作单
export function labelPrintLodopTemp(data) {
  console.log('------', data);
  console.log(data.bar_code);
  return new Promise(async (resolve, reject) => {
    const html = await lodopHelper.print(data);
    document.getElementById("printArea").innerHTML = html;
    let LODOP = getLodop();
    LODOP.PRINT_INIT(new Date().getTime()); // 初始化
    LODOP.SET_PRINTER_INDEX(data.printer_name);//指定某虚拟打印机
    if (data.sizeType == 1) {
      LODOP.SET_PRINT_PAGESIZE(0, 500, 400, "A4");
    } else {
      LODOP.SET_PRINT_PAGESIZE(0, 400, 300, "A4");
    }
    if (data.sizeType == 1) {
      LODOP.ADD_PRINT_HTM(0, '3.5mm', document.getElementById("printArea").offsetWidth, document.getElementById("printArea").offsetHeight, document.getElementById("printArea").innerHTML);
      if (data.printType == 0) {
        LODOP.ADD_PRINT_BARCODE('26mm','3.6mm', 130, 38,"128Auto", data.bar_code);
        LODOP.SET_PRINT_STYLEA(0,"FontSize",7);//设置上面这个条码下方的文字字体大小
      } else {
        LODOP.ADD_PRINT_BARCODE('28mm','3.6mm', 128, 35,"128B", data.bar_code);
        LODOP.SET_PRINT_STYLEA(0,"FontSize",7);//设置上面这个条码下方的文字字体大小
      }
    } else {
      LODOP.ADD_PRINT_HTM(0, '1.6mm', document.getElementById("printArea").offsetWidth, document.getElementById("printArea").offsetHeight, document.getElementById("printArea").innerHTML);
      if (data.printType == 0) {
        LODOP.ADD_PRINT_BARCODE('19mm','1.7mm', 130, 35,"128Auto", data.bar_code);
        LODOP.SET_PRINT_STYLEA(0,"FontSize",7);//设置上面这个条码下方的文字字体大小
      } else {
        LODOP.ADD_PRINT_BARCODE('20mm','1.7mm', 118, 31,"128B", data.bar_code);
        LODOP.SET_PRINT_STYLEA(0,"FontSize",7);//设置上面这个条码下方的文字字体大小
      }
    }
    LODOP.SET_PRINT_MODE("PRINT_START_PAGE", 1);
    LODOP.SET_PRINT_MODE("PRINT_END_PAGE", 1);
    // LODOP.PREVIEW();
    LODOP.PRINT();
    resolve();
  })
}