import store from "../store";

const availables = [1, 2, 3, 4, 5];
const lodopHelper = {

  async print(data) {
    const result = this.getHtml(data);
    // console.log("result", result);
    return result || "无法解析的打印信息";
  },

  getHtml(data) {
    if (!availables.includes(parseInt(data.type))) {
      alert("无法解析的打印信息");
      return;
    }
    const printWidth = parseInt(localStorage.qiantaiPrintSize) || 58;
  
    // 交接班
    if (data.type == 1) {
      let html = ``;
      if (printWidth == 58) {
        html = `<div style="width: 48mm;">`
      } else {
        html = `<div style="width: 70mm;">`
      }
      html += `
        <div style="padding: 5mm 0mm 8mm 0mm;">
          <div style="font-size: 20px; text-align: center; margin-bottom: 10px;">${data.shop_name}</div>
          <div style="font-size: 18px; text-align: center; margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px dashed black;">☆☆日报☆☆</div>
      `
      html += `
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">员工：${data.staff || ''}</div>
          <div style="font-size: 12px; text-align: left;">开始时间：${data.s_time}</div>
          <div style="font-size: 12px; text-align: left;">结束时间：${data.e_time}</div>
        `
      
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        html += `<div style="width: 100%; height: 1px; margin: 8px 0; border-bottom: 1px dashed black;"></div>`
        html += `
            <div style="font-size: 18px; font-weight: bold; margin-bottom: 5px;">${element.name || ''}</div>
          `
        for (let j = 0; j < element.data.length; j++) {
          const d_ele = element.data[j];
          html += `
              <div style="font-size: 12px; margin-bottom: 5px;">${d_ele.n || ''}：${d_ele.v}</div>
            `
        }
      }
      html += `<div style="margin-bottom: 10px;"></div>`
      html += `</div>`
      html += `</div>`
      return html;
    }
    
    // 结账单
    if (data.type == 2) {
      let html = ``;
      if (printWidth == 58) {
        html = `<div style="width: 48mm;">`
      } else {
        html = `<div style="width: 70mm;">`
      }
      html += `
          <div style="padding: 5mm 0mm 8mm 0mm;">
            <div style="font-size: 20px; text-align: center; margin-bottom: 10px;">${data.shop_name}</div>
        ` 
      let xiaop = '';
      if (data.isReprint == 1) {
        xiaop += '☆结账单补打☆'
      } else {
        xiaop = '☆☆结账单☆☆'
      }
      html += `
          <div style="font-size: 18px; font-weight: bold; text-align: center; margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px dashed black;">${xiaop}</div>  
        `
      
      html += `
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">订单单号：${data.order_num}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">收银人员：${data.staff || '无'}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 5px; padding-bottom: 5px; border-bottom: 1px dashed black;">收银时间：${data.pay_time}</div>
        `
      
        if (printWidth == 58) {
        html += `
            <table style="font-size: 12px; margin-bottom: 10px; width: 100%;">
              <tr>
                <th style="text-align: left; width: 19%;">名称</th>
                <th style="text-align: center; width: 27%;">单价</th>
                <th style="text-align: center; width: 27%;">数量</th>
                <th style="text-align: center; width: 27%;">小计</th>
              </tr>
            `
        for (let i = 0; i < data.data.length; i++) {
          const element = data.data[i];
          html += `
              <tr><td colspan="4" style="font-size: 12px;">${i + 1}.${element[0]}</td></tr>
              <tr>
                <td style="text-align: left; width: 19%;"></td>
                <td style="text-align: center; width: 27%;">${element[1]}</td>
                <td style="text-align: center; width: 27%;">${element[2]}</td>
                <td style="text-align: center; width: 27%;">${element[3]}</td>
              </tr>
            `
        }
        
        html += `
            </table>
          `
      } else {
        html += `
            <table style="font-size: 12px; margin-bottom: 10px; width: 100%;">
              <tr>
                <th style="text-align: left; width: 25%;">名称</th>
                <th style="text-align: center; width: 25%;">单价</th>
                <th style="text-align: center; width: 25%;">数量</th>
                <th style="text-align: center; width: 25%;">小计</th>
              </tr>
          `
        for (let i = 0; i < data.data.length; i++) {
          const element = data.data[i];
          html += `
              <tr style="font-size: 12px; margin-bottom: 8px;">
                <td style="text-align: left; width: 25%;">${i + 1}.${element[0]}</td>
                <td style="text-align: center; width: 25%;">${element[1]}</td>
                <td style="text-align: center; width: 25%;">${element[2]}</td>
                <td style="text-align: center; width: 25%;">${element[3]}</td>
              </tr>
            `
        }
        html += `
            </table>
          `
      }
      html += `
          <div style="height: 1px; padding-top: 5px; border-top: 1px dashed black;"></div>
          <div style="font-size: 12px; margin-bottom: 4px;">订单合计：${data.price}</div>
        `
      if (parseFloat(data.discount_amount) > 0) {
        if (data.is_member_price == 1) {
          html += `
              <div style="font-size: 12px; margin-bottom: 4px;">[会员价优惠]：-${data.discount_amount}</div>
            `
        } else {
          html += `
              <div style="font-size: 12px; margin-bottom: 4px;">[会员折扣:${data.member_type.rate >= 100 ? '' : parseFloat(data.member_type.rate / 10).toFixed(2) + '折'}]：-${data.discount_amount}</div>
            `
        }
      }
      if (data.discount_rate_price && data.discount_rate_price > 0) {
        html += `
              <div style="font-size: 12px; margin-bottom: 4px;">[打折金额:${data.discount_rate_text}折]：-${data.discount_rate_price}</div>
            `
      }
      if (data.wipe_price && data.wipe_price > 0) {
        html += `
              <div style="font-size: 12px; margin-bottom: 4px;">[抹零金额]：-${data.wipe_price}</div>
            `
      }
      if (data.score_discount && data.score_discount > 0) {
        html += `
              <div style="font-size: 12px; margin-bottom: 4px;">[积分抵扣]：-${data.score_discount}</div>
            `
      }
      if (data.coupon_price && data.coupon_price > 0) {
        html += `
              <div style="font-size: 12px; margin-bottom: 4px;">[优惠券]：-${data.coupon_price}</div>
            `
      }

      html += `
          <div style="font-size: 12px; margin-bottom: 4px; padding-top: 5px; border-top: 1px dashed black;">应收：${data.total_price}</div>
        `
      if (parseFloat(data.return_money) > 0) {
        html += `
            <div style="font-size: 12px; margin-bottom: 4px;">退款：-${data.return_money}</div>
          `
      }
      html += `
          <div style="font-size: 14px; margin-bottom: 4px; font-weight: bold;">实收${data.pay_type_text ? '(' + data.pay_type_text + ')' : ''}:${data.actual_amount}</div>
        `
      if (data.pay_type == 6) {
        html += `
            <div style="font-size: 12px; text-align: left; margin-bottom: 4px; padding-top: 5px; border-top: 1px dashed black;">会员姓名：${data.member.name || ''}</div>
            <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">会员电话：${data.member.phone && data.member.phone != '' ? data.member.phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') : ''}</div>
            <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">余额：${data.member.balance || ''}</div>
          `;
      }
      html += `
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px; padding-top: 5px; border-top: 1px dashed black;">门店地址：${data.address}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">联系电话：${data.tel}</div>
      `;
      html += `<div style="margin-bottom: 10px;"></div>`
      html += `</div>`
      html += `</div>`
      return html;
    }

    // 充值单
    if (data.type == 3) {
      let html = ``;
      if (printWidth == 58) {
        html = `<div style="width: 48mm;">`
      } else {
        html = `<div style="width: 70mm;">`
      }
      html += `
          <div style="padding: 5mm 0mm 8mm 0mm;">
            <div style="font-size: 20px; text-align: center; margin-bottom: 10px;">${data.shopName}</div>
        ` 
      html += `
          <div style="font-size: 18px; font-weight: bold; text-align: center; margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px dashed black;">☆☆充值单☆☆</div>  
        `
      
      html += `
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">收银人员：${data.shop_admin_name}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">收银时间：${data.recharge_time}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">充值单号：${data.recharge_no}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">会员姓名：${data.member_name}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 5px; padding-bottom: 5px; border-bottom: 1px dashed black;">会员电话：${data.member_phone}</div>
        `

      html += `
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">充值金额：${data.amount}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">赠送金额：${data.give_amount}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">充值前余额：${data.recharge_before}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px; padding-bottom: 5px; border-bottom: 1px dashed black;">充值后余额：${data.recharge_after}</div>
        `

      html += `
          <div style="font-size: 14px; margin-bottom: 4px; font-weight: bold;">实收${data.pay_type_text ? '(' + data.pay_type_text + ')' : ''}:${data.amount}</div>
        `
      
      html += `
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px; padding-top: 5px; border-top: 1px dashed black;">门店地址：${data.shop_address}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">联系电话：${data.shop_phone}</div>
      `;
      html += `<div style="margin-bottom: 10px;"></div>`
      html += `</div>`
      html += `</div>`
      return html;
    }

    // 退货单
    if (data.type == 4) {
      let html = ``;
      if (printWidth == 58) {
        html = `<div style="width: 48mm;">`
      } else {
        html = `<div style="width: 70mm;">`
      }
      html += `
          <div style="padding: 5mm 0mm 8mm 0mm;">
            <div style="font-size: 20px; text-align: center; margin-bottom: 10px;">${data.shop_name}</div>
        ` 
      let xiaop = '';
      if (data.isReprint == 1) {
        xiaop += '☆退货单补打☆'
      } else {
        xiaop = '☆☆退货单☆☆'
      }
      html += `
          <div style="font-size: 18px; font-weight: bold; text-align: center; margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px dashed black;">${xiaop}</div>  
        `
      
      html += `
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">订单单号：${data.order_num}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">收银人员：${data.staff || '无'}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 5px; padding-bottom: 5px; border-bottom: 1px dashed black;">收银时间：${data.pay_time}</div>
        `
      
        if (printWidth == 58) {
        html += `
            <table style="font-size: 12px; margin-bottom: 10px; width: 100%;">
              <tr>
                <th style="text-align: left; width: 19%;">名称</th>
                <th style="text-align: center; width: 27%;">单价</th>
                <th style="text-align: center; width: 27%;">数量</th>
                <th style="text-align: center; width: 27%;">实退</th>
              </tr>
            `
        for (let i = 0; i < data.data.length; i++) {
          const element = data.data[i];
          html += `
              <tr><td colspan="4" style="font-size: 12px;">${i + 1}.${element[0]}</td></tr>
              <tr>
                <td style="text-align: left; width: 19%;"></td>
                <td style="text-align: center; width: 27%;">${element[1]}</td>
                <td style="text-align: center; width: 27%;">${element[2]}</td>
                <td style="text-align: center; width: 27%;">${element[3]}</td>
              </tr>
            `
        }
        
        html += `
            </table>
          `
      } else {
        html += `
            <table style="font-size: 12px; margin-bottom: 10px; width: 100%;">
              <tr>
                <th style="text-align: left; width: 25%;">名称</th>
                <th style="text-align: center; width: 25%;">单价</th>
                <th style="text-align: center; width: 25%;">数量</th>
                <th style="text-align: center; width: 25%;">实退</th>
              </tr>
          `
        for (let i = 0; i < data.data.length; i++) {
          const element = data.data[i];
          html += `
              <tr style="font-size: 12px; margin-bottom: 8px;">
                <td style="text-align: left; width: 25%;">${i + 1}.${element[0]}</td>
                <td style="text-align: center; width: 25%;">${element[1]}</td>
                <td style="text-align: center; width: 25%;">${element[2]}</td>
                <td style="text-align: center; width: 25%;">${element[3]}</td>
              </tr>
            `
        }
        html += `
            </table>
          `
      }

      html += `
          <div style="font-size: 14px; margin-bottom: 4px; font-weight: bold; padding-top: 5px; border-top: 1px dashed black;">实退${data.pay_type_text ? '(' + data.pay_type_text + ')' : ''}:${data.total_price}</div>
        `
      html += `
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px; padding-top: 5px; border-top: 1px dashed black;">门店地址：${data.address}</div>
          <div style="font-size: 12px; text-align: left; margin-bottom: 4px;">联系电话：${data.tel}</div>
      `;
      html += `<div style="margin-bottom: 10px;"></div>`
      html += `</div>`
      html += `</div>`
      return html;
    }

    // 标签制作单
    if (data.type == 5) {
      if (data.sizeType == 1) {
        let html = ``;
        html = `<div style="width: 46.3mm; height: 40mm; overflow: hidden; position: relative;">`
        html += `<div style="padding: 1mm 0mm 0mm 0.1mm; overflow: hidden;">`

        html += `<div style="font-size: 14px; font-weight: 800; text-align: left; margin-bottom: 5px;">${data.name}</div>`
        if (data.printType == 0) {
          html += `<div style="font-size: 12px; text-align: left; margin-bottom: 4px;">售价:￥${data.fact_price || '0'}元/${data.dw}</div>`
        } else {
          html += `<div style="font-size: 11px; text-align: left; margin-bottom: 4px;">单价:￥${data.fact_price || '0'}元/${data.dw}</div>`
          html += `<div style="font-size: 11px; text-align: left; margin-bottom: 4px;">重量:${data.weight || '0'}${data.dw}</div>`
          html += `<div style="font-size: 11px; text-align: left; margin-bottom: 4px;">总价:￥${data.total_price || '0'}元</div>`
        }
        if (data.produce_time && data.produce_time != '') {
          html += `<div style="font-size: 12px; text-align: left; margin-bottom: 4px;">包装日期:${data.produce_time}</div>`
        }
        if (data.printType == 0 && data.quality && data.quality != '' && data.quality > 0) {
          html += `<div style="font-size: 12px; text-align: left; margin-bottom: 4px;">保质期:${data.quality}天</div>`
        }

        html += `</div>`
        if (data.printType == 1 && data.quality && data.quality != '' && data.quality > 0) {
          html += `<div style="font-size: 10px; display: flex; align-items: center; justify-content: flex-end; position: absolute; right: 3px; top: 28mm; z-index: 10;">`
          html += `<div></div>`
          html += `<div><div>保质期</div><div style="margin-top: 2px;">${data.quality}天</div></div>`
          html += `</div>`
        }
        html += `</div>`
        return html;
      } else {
        let html = ``;
        html = `<div style="width: 36.3mm; height: 30mm; overflow: hidden;">`
        html += `<div style="padding: 1mm 0mm 0mm 0.1mm; overflow: hidden;">`

        html += `<div style="font-size: 12px; font-weight: 800; text-align: left; margin-bottom: 1px;">${data.name}</div>`
        if (data.printType == 0) {
          html += `<div style="font-size: 11px; text-align: left; margin-bottom: 1px;">售价:￥${data.fact_price || '0'}/${data.dw}</div>`
        } else {
          html += `<div style="display: flex; align-items: center; justify-content: space-between; font-size: 11px; text-align: left; margin-bottom: 1px;">`
          html += `<div><div>单价</div><div style="margin-top: 1px;">￥${data.fact_price || '0'}元/${data.dw}</div></div>`
          html += `<div><div>重量</div><div style="margin-top: 1px;">${data.weight || '0'}${data.dw}</div></div>`
          html += `</div>`
          html += `<div style="font-size: 11px; text-align: left; margin-bottom: 1px;">总价:￥${data.total_price || '0'}元</div>`
        }
        if (data.produce_time && data.produce_time != '') {
          html += `<div style="font-size: 11px; text-align: left; margin-bottom: 1px;">包装日期:${data.produce_time}</div>`
        }
        if (data.printType == 0 && data.quality && data.quality != '' && data.quality > 0) {
          html += `<div style="font-size: 11px; text-align: left; margin-bottom: 1px;">保质期:${data.quality}天</div>`
        } else if (data.printType == 1 && data.quality && data.quality != '' && data.quality > 0) {
          html += `<div style="font-size: 10px; display: flex; align-items: center; justify-content: flex-end; margin-top: 4px;">`
          html += `<div></div>`
          html += `<div><div>保质期</div><div style="margin-top: 2px;">${data.quality}天</div></div>`
          html += `</div>`
        }

        html += `</div>`
        html += `</div>`
        return html;
      }
    }

  }
}

export default lodopHelper;
