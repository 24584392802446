import request from '@/request/http';

const lcy = {
  // 下单-扫码增加商品、减少商品
  sweepCode: data => {
    return request({
      url: 'Order/sweepCode',
      data
    })
  },
}

export default lcy