const config = {
  // 开发
  // baseUrl: 'https://dzb.api.casher.kissneck.net/api/',
  // siteUrl: 'https://dzb.api.casher.kissneck.net/',

  // 灰度
  // baseUrl: 'https://cashier.s.dianzhangbao.com.cn/api/',
  // siteUrl: 'https://cashier.s.dianzhangbao.com.cn/',

  // 正式
  baseUrl: 'https://cashier.api.dianzhangbao.com.cn/api/',
  siteUrl: 'https://cashier.api.dianzhangbao.com.cn/',

  netUrl: 'http://localhost:3001/',
}

export default config
