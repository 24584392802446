import request from '@/request/http';
import config from '../../config';

const dt = {
  // 拉取商品分类列表
  getCategory: data => {
    return request({
      url: 'Category/getCategory',
      data
    })
  },
  // 商品列表
  goodsList: (data) => {
    return request({
      url: 'Good/goodsList',
      data
    })
  },
  // 获取商品详情
  getGoodInfo: data => {
    return request({
      url: 'Good/getGoodInfo',
      data
    })
  },
  // 新增编辑删除商品
  actionGoods: data => {
    return request({
      url: 'Goods/actionGoods',
      data,
      method: 'post'
    })
  },
  // 交接班显示页面
  handoverShow: data => {
    return request({
      url: 'Order/handoverShow',
      data
    })
  },
  // 下单
  OrderPay: (data, dontLoad) => {
    return request({
      url: 'Order/pay',
      data,
      method: 'post'
    }, dontLoad)
  },
  // 交接班-销售商品列表
  handoverOrder: data => {
    return request({
      url: 'Order/handoverOrder',
      data
    })
  },
  // 门店销售/退货订单
  orderList: data => {
    return request({
      url: 'Order/orderList',
      data
    })
  },
  // 订单详情
  orderInfo: data => {
    return request({
      url: 'Order/orderInfo',
      data
    })
  },
  // 取单-挂单列表
  hangList: data => {
    return request({
      url: 'Order/hangList',
      data
    })
  },
  // 删除挂单订单
  orderDelete: data => {
    return request({
      url: 'Order/orderDelete',
      data,
      method: 'post'
    })
  },

  // 线上商品列表
  getOnlineShopGoodsList: data => {
    return request({
      url: 'Hj/getOnlineShopGoodsList',
      data
    })
  },
  // 线上商品同步至收银端
  stepOnlineProductToShop: data => {
    return request({
      url: 'Hj/stepOnlineProductToShop',
      data,
      method: 'post'
    })
  },
  // 会员列表
  getStoreMemberList: data => {
    return request({
      url: 'Hj/getMemberList',
      data
    })
  },
  // 会员详情
  getMemberDetail: data => {
    return request({
      url: 'Hj/getMemberDetail',
      data
    })
  },
  // 会员充值列表
  getMemberChargeOrderList: data => {
    return request({
      url: 'Hj/getMemberChargeOrderList',
      data
    })
  },
  // 线上核销订单列表
  getOnlineShopOrderList: data => {
    return request({
      url: 'Hj/getOnlineShopOrderList',
      data
    })
  },
  // 线上核销订单详情
  getOnlineShopOrderDetail: data => {
    return request({
      url: 'Hj/getOnlineShopOrderDetail',
      data
    })
  },
  // 线上订单在线下核销
  onlineOrderWriteOff: data => {
    return request({
      url: 'Hj/onlineOrderWriteOff',
      data,
      method: 'post'
    })
  },
  // 创建会员
  createMember: data => {
    return request({
      url: 'Hj/createMember',
      data,
      method: 'post'
    })
  },
  // 编辑会员
  updateMember: data => {
    return request({
      url: 'Hj/updateMember',
      data,
      method: 'post'
    })
  },
  // 会员充值支付
  memberCharge: data => {
    return request({
      url: 'Hj/memberCharge',
      data,
      method: 'post'
    })
  },
  // 会员充值金额列表
  getRechargeSetList: data => {
    return request({
      url: 'Hj/getRechargeSetList',
      data
    })
  },
  // 线上订单在线下核销
  // onlineOrderWriteOff: data => {
  //   return request({
  //     url: 'Hj/onlineOrderWriteOff',
  //     data,
  //     method: 'post'
  //   })
  // },
  // 上传位置信息
  lonLat: data => request({ url: config.httpBaseURL + 'lonLat', data, method: 'GET' }), //上传位置

  // 调货记录
  getStockTransferList: data => {
    return request({
      url: 'Output/onlineStockRecord',
      data
    })
  },

  //店铺列表
  getShopList: data => {
    return request({
      url: 'Shop/ShopList',
      data
    })
  },

  // 调货
  addStockTransfer: data => {
    return request({
      url: 'Good/transProduct',
      data,
      method: 'post'
    })
  },
}

export default dt
