import request from '@/request/http';
import dt from '@/request/dt';
import cc from '@/request/cc';
import lcy from './lcy';
import pb from "@/request/pb";
import dt_api from './dt_api';

const api = {
    ...dt,
    ...cc,
    ...lcy,
    ...pb,
    ...dt_api
}

export default api
