import request from '@/request/http';
import config from '../../config';

// 店掌宝零售收银，新接口
const dt_api = {
  getMemberTypeList: data => { return request({ url: 'membertype/getMemberTypeList', data }) }, // 获取会员等级
  addMemberType: data => { return request({ url: 'membertype/addMemberType', data, method: 'post' }) }, // 新增会员等级
  editMemberType: data => { return request({ url: 'membertype/editMemberType', data, method: 'post' }) }, // 编辑会员等级
  delMemberType: data => { return request({ url: 'membertype/delMemberType', data, method: 'post' }) }, // 删除会员等级
  getMemberTypeInfo: data => { return request({ url: 'membertype/getMemberTypeInfo', data, method: 'get' }) }, // 获取会员等级详情

  dt_getMemberList: data => { return request({ url: 'member/getMemberList', data, method: 'get' }) }, // 获取会员列表
  dt_addMember: data => { return request({ url: 'member/addMember', data, method: 'post' }) }, // 创建会员
  dt_editMember: data => { return request({ url: 'member/editMember', data, method: 'post' }) }, // 编辑会员
  dt_delMember: data => { return request({ url: 'member/delMember', data, method: 'post' }) }, // 删除会员
  dt_getCardNum: data => { return request({ url: 'member/getCardNum', data, method: 'get' }) }, // 获取会员码

  dt_rechargeRecord: data => { return request({ url: 'member/rechargeRecord', data, method: 'get' }) }, // 会员充值记录
  dt_expensesRecord: data => { return request({ url: 'member/expensesRecord', data, method: 'get' }) }, // 会员消费记录
  dt_resiveRecord: data => { return request({ url: 'member/resiveRecord', data, method: 'get' }) }, // 会员金额调整记录
  dt_editStatus: data => { return request({ url: 'member/editStatus', data, method: 'post' }) }, // 修改会员状态
  dt_memberRecharge: data => { return request({ url: 'payment/memberRecharge', data, method: 'post' }) }, // 会员充值、调整余额

  dt_getShopInfo: data => { return request({ url: 'shop/getShopInfo', data, method: 'get' }) }, // 获取门店信息
  dt_updateShopInfo: data => { return request({ url: 'shop/updateShopInfo', data, method: 'post' }) }, // 编辑门店信息
  dt_changeModel: data => { return request({ url: 'shop/changeModel', data, method: 'post' }) }, // 切换模式

  dt_getPrintSet: data => { return request({ url: 'print/getPrintSet', data, method: 'get' }) }, // 获取打印设置
  dt_PrintSet: data => { return request({ url: 'print/PrintSet', data, method: 'post' }) }, // 编辑打印设置
  dt_printerList: data => { return request({ url: 'print/printerList', data, method: 'get' }) }, // 获取打印机列表
  dt_getPrinterInfo: data => { return request({ url: 'print/getPrinterInfo', data, method: 'get' }) }, // 获取打印机信息
  dt_addPrinter: data => { return request({ url: 'print/addPrinter', data, method: 'post' }) }, // 新增打印机
  dt_editPrinter: data => { return request({ url: 'print/editPrinter', data, method: 'post' }) }, // 编辑打印机
  dt_deletePrinter: data => { return request({ url: 'print/deletePrinter', data, method: 'post' }) }, // 删除打印机

  dt_getSmsSet: data => { return request({ url: 'sms/getSmsSet', data, method: 'get' }) }, // 获取短信设置
  dt_setSmsConfig: data => { return request({ url: 'sms/setSmsConfig', data, method: 'post' }) }, // 编辑短信设置
  dt_getSmsRecord: data => { return request({ url: 'sms/getSmsRecord', data, method: 'get' }) }, // 获取短信记录

  dt_editManagePass: data => { return request({ url: 'shop/editManagePass', data, method: 'post' }) }, // 修改管理密码
  dt_editShopPass: data => { return request({ url: 'shop/editShopPass', data, method: 'post' }) }, // 修改店长密码

  // 大趋云打印机
  daqPrint_dqPrint: data => { return request({ url: `daqPrint/dq-print`, data, method: 'post' }) }, // 大趋小票云打印接口
  daqPrint_dqQr: data => { return request({ url: `daqPrint/dq-qr`, data, method: 'post' }) }, // 大趋小票云打印二维码接口
  daqPrint_dqPrintStatus: data => { return request({ url: `daqPrint/dq-printStatus`, data, method: 'get' }) }, // 大趋查询云打印小票打印机状态

  dt_payOrder: data => { return request({ url: `orderplace/payOrder`, data, method: 'post' }) }, // 下单

  getLocalIp: data => { return request({ url: config.netUrl + 'getLocalIp', data, method: 'get' }) },//获取本地设备IP、mac
  netPrint: data => { return request({ url: config.netUrl + 'print', data, method: 'post' }) },//调用网络打印
  openCasher: data => { return request({ url: config.netUrl + 'openCasher', data, method: 'get' }) },//打开钱箱

  dt_placeAnOrder: data => { return request({ url: 'Order/placeAnOrder', data, method: 'post' }) },//下单
  dt_immediatePay: data => { return request({ url: 'Order/immediatePay', data, method: 'post' }) },//立即支付

  Report_getOrderSummary: data => { return request({ url: 'Report/getOrderSummary', data, method: 'get' }) },//账单汇总
  Report_getOrderDetails: data => { return request({ url: 'Report/getOrderDetails', data, method: 'get' }) },//订单明细
  Report_getRechargeDetails: data => { return request({ url: 'Report/getRechargeDetails', data, method: 'get' }) },//充值记录
  Report_getProductSales: data => { return request({ url: 'Report/getProductSalesNew', data, method: 'get' }) },//商品销量
  Report_getMemberPayCount: data => { return request({ url: 'Report/getMemberPayCount', data, method: 'get' }) },//客户分析
  Report_getOrderCollectionCount: data => { return request({ url: 'Report/getOrderCollectionCount', data, method: 'get' }) },//订单收款
  Report_getOperateSummary: data => { return request({ url: 'Report/getOperateSummary', data, method: 'get' }) },//营业快报
  Report_getBusinessTrends: data => { return request({ url: 'Report/getBusinessTrends', data, method: 'get' }) },//营业趋势
  Report_getProductRanking: data => { return request({ url: 'Report/getProductRanking', data, method: 'get' }) },//商品排行
  Report_getMemberRanking: data => { return request({ url: 'Report/getMemberRanking', data, method: 'get' }) },//会员排行

  Order_refundOrder: data => { return request({ url: 'Order/refundOrder', data, method: 'post' }) },//订单收款

  shop_scoreGet: data => { return request({ url: 'shop/scoreGet', data, method: 'get' }) },//获取积分设置
  shop_scoreSet: data => { return request({ url: 'shop/scoreSet', data, method: 'post' }) },//积分规则设置
  scoreLog_getList: data => { return request({ url: 'scoreLog/getList', data, method: 'get' }) },//获取积分明细

  getProductsUnit: data => { return request({ url: 'productsUnit', data, method: 'get' }) },//单位列表
  addProductsUnit: data => { return request({ url: 'productsUnit', data, method: 'post' }) },//添加单位
  editProductsUnit: (id, data) => { return request({ url: `productsUnit/${id}`, data, method: 'put' }) },//编辑单位
  deleteProductsUnit: (id, data) => { return request({ url: `productsUnit/${id}`, data, method: 'delete' }) },//删除单位

  ShopStockLog_addStock: data => { return request({ url: 'ShopStockLog/addStock', data, method: 'post' }) },//添加库存变动-调整库存
  ShopStockLog_getList: data => { return request({ url: 'ShopStockLog/getList', data, method: 'get' }) },//库存变动记录
  inventoryLog_addInventoryLog: data => { return request({ url: 'inventoryLog/addInventoryLog', data, method: 'post' }) },//添加盘点记录-库存盘点
  inventoryLog_getList: data => { return request({ url: 'inventoryLog/getList', data, method: 'get' }) },//盘点记录
  inventoryLog_getListInfo: data => { return request({ url: 'inventoryLog/getListInfo', data, method: 'get' }) },//盘点记录详情

  HandoverLog_getList: data => { return request({ url: 'HandoverLog/getList', data, method: 'get' }) },//交接班记录

  jiabo_print: data => { return request({ url: `jiab/print`, data, method: 'post' }) }, // 佳博打印小票
  jiabo_tagPrint: data => { return request({ url: `Jiab/tagPrint`, data, method: 'post' }) }, // 佳博打印标签

  getTagPrintSet: data => { return request({ url: 'print/getTagPrintSet', data, method: 'get' }) },//获取标签设置
  setTagPrintSet: data => { return request({ url: 'print/setTagPrintSet', data, method: 'post' }) },//标签设置
  printTag: data => { return request({ url: 'product/printTag', data, method: 'post' }) },//称重获取标签

  editPrice: data => { return request({ url: 'Order/editPrice', data, method: 'post' }) }, // 购物车商品改价
  customized: data => { return request({ url: 'Order/customized', data, method: 'post' }) }, // 定制商品

  Coupon_getList: data => { return request({ url: 'Coupon/getList', data, method: 'get' }) }, // 优惠券列表
  Coupon_addCoupon: data => { return request({ url: 'Coupon/addCoupon', data, method: 'post' }) }, // 优惠券 添加
  Coupon_editCoupon: data => { return request({ url: 'Coupon/editCoupon', data, method: 'post' }) }, // 优惠券 编辑
  Coupon_couponInfo: data => { return request({ url: 'Coupon/couponInfo', data, method: 'post' }) }, // 优惠券 详情
  Coupon_delCoupon: data => { return request({ url: 'Coupon/delCoupon', data, method: 'post' }) }, // 优惠券 删除
  Coupon_sendCoupon: data => { return request({ url: 'Coupon/sendCoupon', data, method: 'post' }) }, // 优惠券 发放
  CouponLog_getList: data => { return request({ url: 'CouponLog/getList', data, method: 'get' }) }, // 优惠券发放记录
  Coupon_memberCoupon: data => { return request({ url: 'Coupon/memberCoupon', data, method: 'get' }) }, // 获取用户优惠券

  getRechangeActivity: data => { return request({ url: 'RechangeActivity', data, method: 'get' }) },//充值有礼列表
  getRechangeActivityInfo: (id, data) => { return request({ url: `RechangeActivity/${id}`, data, method: 'get' }) },//充值有礼详情
  addRechangeActivity: data => { return request({ url: 'RechangeActivity', data, method: 'post' }) },//添加充值有礼
  editRechangeActivity: (id, data) => { return request({ url: `RechangeActivity/${id}`, data, method: 'put' }) },//编辑充值有礼
  deleteRechangeActivity: (id, data) => { return request({ url: `RechangeActivity/${id}`, data, method: 'delete' }) },//删除充值有礼

  getPointsExchange: data => { return request({ url: 'PointsExchange', data, method: 'get' }) },//积分兑换列表
  getPointsExchangeInfo: (id, data) => { return request({ url: `PointsExchange/${id}`, data, method: 'get' }) },//积分兑换详情
  addPointsExchange: data => { return request({ url: 'PointsExchange', data, method: 'post' }) },//添加积分兑换
  editPointsExchange: (id, data) => { return request({ url: `PointsExchange/${id}`, data, method: 'put' }) },//编辑积分兑换
  deletePointsExchange: (id, data) => { return request({ url: `PointsExchange/${id}`, data, method: 'delete' }) },//删除积分兑换
  userExchange: data => { return request({ url: 'PointsExchange/userExchange', data, method: 'post' }) },//会员积分兑换

  hidden_register: data => { return request({ url: 'login/is_hidden', data, method: 'get' }) },//登录页面注册引导显示隐藏，为了iOS审核
}

export default dt_api
