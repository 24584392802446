import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from '@/request/api';
import store from './store'
import '@/style/reset.css'
import '@/style/common.css';
import '@vant/touch-emulator';
import Vant from 'vant';
import 'vant/lib/index.css';
import less from 'less'
import { getLocalStorage, setLocalStorage, getObjectLocalStorage, setObjectLocalStorage, removeLocalStorage } from './storage/storage'
Vue.use(less);

// 商米电子称服务
import scaleService from '@/utils/ScaleService.js';
Vue.prototype.$scaleService = scaleService;

import html2canvas from 'html2canvas';
// import Vue from 'vue'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(VXETable)

// 真机调试控制台打印
// import Vconsole from 'vconsole';
// const vConsole = new Vconsole();
// Vue.use(vConsole);


Vue.use(Vant);
Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$html2canvas = html2canvas;
Vue.prototype.$store = store;

import device from "./utils/device";
Vue.prototype.$device = device;

import printFunc from "./utils/print-func";
Vue.prototype.$printFunc = printFunc;

// 自适应
fnResize()
window.onresize = function () {
  fnResize();
}

function fnResize() {
  // 获取视口宽度
  var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
  // 计算rem对应的字体大小
  var fontSize = deviceWidth / 192;
  // 设置字体大小
  document.documentElement.style.fontSize = fontSize + 'px';
  // document.documentElement.style.fontSize = "10px"
}

// document.addEventListener('deviceready', function () {
//   // 初始化打印机
//   KissSunmiPrint.showToast([1, 2]).then(function (msg) {
//     console.log("============================1" + msg);
//   }).catch(function (error) {
//     console.log("============================2" + error);
//   });

//   StatusBar.hide();
// }, false);

Vue.prototype.$numeral = require('numeral')
Vue.prototype.$bigN = require('bignumber.js')

// 监听电子秤值重量改变
window.addEventListener("newWeighData", ({ detail }) => {
  store.commit('updateWeightDataDetail', detail);
})

//本地存储（storage）
Vue.prototype.$getLocalStorage = getLocalStorage;
Vue.prototype.$setLocalStorage = setLocalStorage;
Vue.prototype.$getObjectLocalStorage = getObjectLocalStorage;
Vue.prototype.$setObjectLocalStorage = setObjectLocalStorage;
Vue.prototype.$removeLocalStorage = removeLocalStorage;

Number.prototype.toPrice = function() { // 保留两位小数不四舍五入
  let num = this.toString()
  let index = num.indexOf('.')
  if (index !== -1) {
    num = num.substring(0, 2 + index + 1)
  } else {
    num = num.substring(0)
  }
  return parseFloat(num).toFixed(2)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

